import { createSlice } from "@reduxjs/toolkit";
import { Socket } from "socket.io-client";
import { DefaultEventsMap } from "@socket.io/component-emitter";

export interface IMasterState {
  socket: Socket<DefaultEventsMap, DefaultEventsMap> | undefined;
}

const initialState: IMasterState = {
  socket: undefined,
};

const SocketSlice = createSlice({
  name: "socket",
  initialState,
  reducers: {
    setSocket: (state, action) => ({
      ...state,
      socket: action.payload,
    }),
  },
});

export const { setSocket } = SocketSlice.actions;
export default SocketSlice;
