import { getToken, jsonToURI } from "../../utils/general";
import { interceptorFunction } from "../interceptors";
const interceptor = interceptorFunction();

export const commandList = (data = {}) =>
  interceptor.get(`/model/command?data=${jsonToURI(data)}`, {
    headers: {
      authorization: `Bearer ${getToken()}`,
    },
  });

export const commandMaster = () =>
  interceptor.get(`/model/command/master`, {
    headers: {
      authorization: `Bearer ${getToken()}`,
    },
  });

export const getCommand = (id: string) =>
  interceptor.get(`/model/command/${id}`, {
    headers: {
      authorization: `Bearer ${getToken()}`,
    },
  });

export const addCommand = (data: any) =>
  interceptor.post(`/model/command`, data, {
    headers: {
      authorization: `Bearer ${getToken()}`,
    },
  });

export const updateCommand = (id: string, data: any) =>
  interceptor.patch(`/model/command/${id}`, data, {
    headers: {
      authorization: `Bearer ${getToken()}`,
    },
  });

export const deleteCommand = (id: string) =>
  interceptor.delete(`/model/commands/${id}`, {
    headers: {
      authorization: `Bearer ${getToken()}`,
    },
  });

export const sendCommand = (data = {}) =>
  interceptor.post(`/command-send-to-device`, data, {
    headers: {
      authorization: `Bearer ${getToken()}`,
    },
  });

export const sendDeviceLogCommand = (data = {}) =>
  interceptor.post(`/super/send-log-command`, data, {
    headers: {
      authorization: `Bearer ${getToken()}`,
    },
  });

export const checkValidIp = () =>
  interceptor.post(
    `/super/is-ip-valid`,
    {},
    {
      headers: {
        authorization: `Bearer ${getToken()}`,
      },
    },
  );
