import Cookies from "js-cookie";
import moment from "moment";
import { json2csv } from "json-2-csv";

export const readCookie = () => {
  const token = Cookies.get("token");
  const refreshToken = Cookies.get("refreshToken");
  const sessionTime: any = Cookies.get("sessionTime");

  return [token || "", refreshToken || "", sessionTime || ""];
};

export const setCookie = (
  token: string,
  refreshToken: string,
  sessionTime?: string | number | any,
) => {
  const isLocal = process.env.NEXT_PUBLIC_IS_LOCAL === "true";
  Cookies.set(
    "token",
    token,
    isLocal
      ? {}
      : {
          domain: ".intuions.com",
          secure: true,
        },
  );
  Cookies.set(
    "refreshToken",
    refreshToken,
    isLocal
      ? {}
      : {
          domain: ".intuions.com",
          secure: true,
        },
  );
  if (sessionTime) {
    Cookies.set(
      "sessionTime",
      sessionTime,
      isLocal
        ? {}
        : {
            domain: ".intuions.com",
            secure: true,
          },
    );
  }
};

export const clearStorage = () => {
  const isLocal = process.env.NEXT_PUBLIC_IS_LOCAL === "true";
  Cookies.remove(
    "token",
    isLocal
      ? {}
      : {
          domain: ".intuions.com",
          secure: true,
        },
  );
  Cookies.remove(
    "refreshToken",
    isLocal
      ? {}
      : {
          domain: ".intuions.com",
          secure: true,
        },
  );
  Cookies.remove(
    "sessionTime",
    isLocal
      ? {}
      : {
          domain: ".intuions.com",
          secure: true,
        },
  );
};

export const clearOldToken = () => {
  if (process.env.NEXT_PUBLIC_IS_LOCAL === "true") return;
  Cookies.remove("token");
  Cookies.remove("refreshToken");
  Cookies.remove("sessionTime");
};

export const getToken = () => {
  const token = Cookies.get("token");
  return token ?? "";
};

export const jsonToURI = (json: object) => encodeURIComponent(JSON.stringify(json));

export const uriToJSON = (uri: string) => JSON.parse(decodeURIComponent(uri));

export const getCountry = (countries: any, id: number): Record<string, any> =>
  countries.find((country: any) => country.id === id);

export const getState = (countries: any, id: number): Record<string, any> => {
  let stateObj = {};
  /* eslint array-callback-return: "off" */
  countries.find((country: any) => {
    /* eslint array-callback-return: "off" */
    country.states.find((state: any) => {
      if (state.gid === id) {
        stateObj = state;
      }
    });
  });
  return stateObj;
};

export const dateFormatter = (date: string | number) => moment(date).format("DD/MM/YYYY hh:mm A");

export const removeEmptyObjProperties = (obj: any) => {
  Object.keys(obj).forEach((key) => {
    if (obj[key] === null || obj[key] === undefined || obj[key] === "") {
      // eslint-disable-next-line no-param-reassign
      delete obj[key];
    }
  });
  return obj;
};

function isLeapYear(year: number) {
  return (year % 4 === 0 && year % 100 !== 0) || year % 400 === 0;
}

export const dateDifference = (dateStr1: string, dateStr2: string) => {
  if (dateStr2 < dateStr1) {
    return {
      isExpired: true,
    };
  }
  // Convert strings to Date objects
  const date1 = new Date(dateStr1);
  const date2 = new Date(dateStr2);

  // Calculate the time difference in milliseconds
  // @ts-ignore
  const timeDifference = date2 - date1;

  // Define the number of days in each month (leap year and non-leap year)
  const daysInMonth = [
    31, // January
    isLeapYear(date2.getUTCFullYear()) ? 29 : 28, // February
    31, // March
    30, // April
    31, // May
    30, // June
    31, // July
    31, // August
    30, // September
    31, // October
    30, // November
    31, // December
  ];

  // Calculate the difference in years, months, and days
  let yearsDifference = date2.getUTCFullYear() - date1.getUTCFullYear();
  let monthsDifference = date2.getUTCMonth() - date1.getUTCMonth();
  let daysDifference = date2.getUTCDate() - date1.getUTCDate();

  // Adjust negative differences
  if (daysDifference < 0) {
    monthsDifference--;
    daysDifference += daysInMonth[(date2.getUTCMonth() + 11) % 12]; // Adjust for the previous month
  }

  if (monthsDifference < 0) {
    yearsDifference--;
    monthsDifference += 12;
  }

  // Convert remaining milliseconds to different units
  const millisecondsDifference = timeDifference % 1000;
  const secondsDifference = Math.floor(timeDifference / 1000) % 60;
  const minutesDifference = Math.floor(timeDifference / (1000 * 60)) % 60;
  const hoursDifference = Math.floor(timeDifference / (1000 * 60 * 60)) % 24;

  // Create and return an object with the differences
  const timeObject = {
    isExpired: false,
    years: yearsDifference,
    months: monthsDifference,
    days: daysDifference,
    hours: hoursDifference,
    minutes: minutesDifference,
    seconds: secondsDifference,
    milliseconds: millisecondsDifference,
  };

  return timeObject;
};

// eslint-disable-next-line no-promise-executor-return
export const wait = (ms: number) => new Promise((resolve) => setTimeout(resolve, ms));

export const handleDownloadCsv = async (jsonData: any, fileName: string = "data") => {
  const csvData = json2csv(jsonData);
  const blob = new Blob([csvData], { type: "text/csv" });
  const url = URL.createObjectURL(blob);
  const link = document.createElement("a");
  link.href = url;
  const tempFileName = `${fileName}_${new Date().getTime()}.csv`;
  link.setAttribute("download", tempFileName);
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};

export const copyToClipboard = (text: string) => {
  navigator.clipboard.writeText(text);
};

export const addToFormData = (formData: FormData, data: Record<string, any>) => {
  // eslint-disable-next-line no-restricted-syntax
  for (const key in data) {
    // eslint-disable-next-line no-prototype-builtins
    if (data.hasOwnProperty(key)) {
      if (typeof data[key] === "object" && !(data[key] instanceof File)) {
        addToFormData(formData, data[key]);
      } else if (data[key] instanceof File) {
        // Do nothing for now, files will be appended later
      } else {
        formData.append(key, data[key]);
      }
    }
  }

  // Now append files
  // eslint-disable-next-line no-restricted-syntax
  for (const key in data) {
    // eslint-disable-next-line no-prototype-builtins
    if (data.hasOwnProperty(key) && data[key] instanceof File) {
      formData.append(key, data[key]);
    }
  }
};

// Function to retrieve a value from an object based on a given path
export const getValueFromPath = (mainObj: Record<string, any>, path: string) => {
  const obj = JSON.parse(JSON.stringify(mainObj));
  // Check if both obj and path are provided
  if (obj && path) {
    try {
      // Split the path into keys
      const keys = path.split(".");
      let tempObj = obj;

      // Loop through each key in the path
      // eslint-disable-next-line no-restricted-syntax
      for (const key of keys) {
        // If tempObj is null, undefined, or not an object, return 0
        if (!tempObj || typeof tempObj !== "object") {
          return 0;
        }
        // Move to the next nested object using the current key
        tempObj = tempObj[key];
      }

      // Return the value found at the end of the path
      return tempObj;
    } catch (error) {
      // Catch any errors that occur and return 0
      return 0;
    }
  } else {
    // If either obj or path is not provided, return 0
    return 0;
  }
};

// This function takes a sum of bits and separates it into individual bit positions that are set to 1.
export const separateBits = (sumOfBits: number) => {
  let tempSumOfBits = sumOfBits; // Store the input sumOfBits in a temporary variable
  const result = []; // Initialize an empty array to store individual bit positions
  let currentBit = 1; // Start with the least significant bit position (1)

  // Loop until all bits in tempSumOfBits are processed
  while (tempSumOfBits > 0) {
    // Check if the current least significant bit is set to 1
    // eslint-disable-next-line no-bitwise
    if (tempSumOfBits & 1) {
      result.push(currentBit); // If true, push the current bit position to the result array
    }
    // eslint-disable-next-line no-bitwise
    tempSumOfBits >>= 1; // Right shift tempSumOfBits to process the next bit position
    // eslint-disable-next-line no-bitwise
    currentBit <<= 1; // Left shift currentBit to move to the next bit position
  }

  return result; // Return the array containing individual bit positions where bits are set to 1
};

export const removeFalsyValues = (obj: any): any => {
  if (obj === null || obj === undefined) return obj;

  if (Array.isArray(obj)) {
    // Filter out falsy values and recursively clean up non-falsy values
    return obj
      .map(removeFalsyValues)
      .filter(
        (item) =>
          item !== undefined &&
          item !== null &&
          !(Array.isArray(item) && item.length === 0) &&
          !(typeof item === "object" && Object.keys(item).length === 0),
      );
  }
  if (typeof obj === "object") {
    const cleanedObj = Object.entries(obj).reduce((acc: any, [key, value]) => {
      const cleanedValue = removeFalsyValues(value);
      if (
        cleanedValue !== undefined &&
        cleanedValue !== null &&
        !(Array.isArray(cleanedValue) && cleanedValue.length === 0) &&
        !(typeof cleanedValue === "object" && Object.keys(cleanedValue).length === 0)
      ) {
        acc[key] = cleanedValue;
      }
      return acc;
    }, {});
    return cleanedObj;
  }

  // Return non-falsy values
  return obj || undefined;
};

/**
 * Reorders the keys of an object based on the specified key order.
 *
 * @param {Object} obj - The object whose keys are to be reordered.
 * @param {Array} keyOrder - The array specifying the new order of keys.
 * @returns {Object} - A new object with keys reordered according to the keyOrder array.
 */
export const reorderObjectKeys = (mainObj: Record<string, any>, keyOrder: string[]) => {
  const obj = JSON.parse(JSON.stringify(mainObj || {}));
  const reorderedObj: Record<string, any> = {};

  // Add the keys from keyOrder array
  keyOrder.forEach((key) => {
    if (key in obj) {
      reorderedObj[key] = obj[key];
    }
  });

  // Add any remaining keys that were not in the keyOrder array
  Object.keys(obj).forEach((key) => {
    if (!keyOrder.includes(key)) {
      reorderedObj[key] = obj[key];
    }
  });

  return reorderedObj;
};

export const deepEqualObject = (obj1: any, obj2: any) => {
  if (obj1 === obj2) {
    return true;
  }

  if (obj1 === null || obj2 === null) {
    return false;
  }

  if (typeof obj1 !== "object" || typeof obj2 !== "object") {
    return false;
  }

  const keys1 = Object.keys(obj1);
  const keys2 = Object.keys(obj2);

  if (keys1.length !== keys2.length) {
    return false;
  }

  // eslint-disable-next-line no-restricted-syntax
  for (const key of keys1) {
    if (!keys2.includes(key) || !deepEqualObject(obj1[key], obj2[key])) {
      return false;
    }
  }

  return true;
};

export const censorEmail = (email: string, keepChars = 2) => {
  // Split the email into local part and domain part
  const [localPart, domainPart] = email.split("@");

  // If the local part is shorter than or equal to keepChars, return it as is
  if (localPart.length <= keepChars) {
    return email; // Do not censor if too short
  }

  // Get the first `keepChars` characters and calculate how many to replace
  const firstPart = localPart.slice(0, keepChars);
  const remaining = localPart.slice(keepChars);

  // Replace the remaining characters with asterisks
  const censoredLocalPart = firstPart + "*".repeat(remaining.length);

  // Return the censored email
  return `${censoredLocalPart}@${domainPart}`;
};

export const censorPhone = (phone: string, keepDigits = 4) => {
  // Normalize the phone number by removing non-digit characters
  const normalizedPhone = phone.replace(/\D/g, "");

  // Validate phone number length (considering typical lengths)
  if (normalizedPhone.length < keepDigits) {
    throw new Error("Invalid phone number format");
  }

  // Get the last `keepDigits` characters
  const visiblePart = normalizedPhone.slice(-keepDigits);
  const hiddenPart = normalizedPhone.slice(0, -keepDigits);

  // Replace hidden part with asterisks
  const censoredPhone = "*".repeat(hiddenPart.length) + visiblePart;

  return censoredPhone;
};
